import * as LDClient from "launchdarkly-js-client-sdk";
import appSettings from "../appSettings";
import FeatureFlagConstants from "../constants/featureFlagConstants";
import store from "../store";

const clientSideID = appSettings.featuresClientSideId;
let ldClient = null;

function getUserFullName(user) {
    return user.firstName + " " + user.lastName;
}

function getUserCompanyKey(company, user) {
    return company.pakServiceId + "-" + user.pakServiceId;
}

function flagChanged() {
    const flagValue = ldClient.variation(FeatureFlagConstants.MESSAGING, false);
    store.dispatch("featuresStore/updateMessagingFlag", flagValue);
}

export default {
    init: async (company, user) => {
        const context = {
            kind: 'user',
            key: getUserCompanyKey(company, user),
            companyName: company.name,
            name: getUserFullName(user),
            email: user.emailAddress,
        };

        ldClient = LDClient.initialize(clientSideID, context);

        ldClient.on('ready', flagChanged);
        ldClient.on('change', flagChanged);

        try {
            // Wait for initialization with a timeout of 5 seconds
            await ldClient.waitForInitialization(5000);
        } catch (error) {
            // "Initialization timed out" if it exceeds 5 seconds
            console.error(error.message);
        }
    },
};