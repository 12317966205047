import { environment } from "./env";

const devConfig = {
  sendBirdAppId: "BF761123-7A85-4A1A-9DB3-F705FCE35626",
  featuresClientSideId: "672bb61b1738830881641a40",
};

const qaConfig = {
  sendBirdAppId: "A02B95DF-9348-4DCD-8FD8-2A6F59BC4E7D",
  featuresClientSideId: "672bb5ecedf12a08738c229f",
};

const uatConfig = {
  sendBirdAppId: "",
  featuresClientSideId: "672bb60e1d32ab0880c61501",
};

const preConfig = {
  sendBirdAppId: "",
  featuresClientSideId: "672bb60865d29c0845e4f40d",
};

const prodConfig = {
  sendBirdAppId: "",
  featuresClientSideId: "672bb5ecedf12a08738c22a0",
};

const config = {
  dev: devConfig,
  qa: qaConfig,
  uat: uatConfig,
  pre: preConfig,
  prod: prodConfig,
};

let currentConfig = config[environment];

export default {
  sendBirdAppId: currentConfig.sendBirdAppId,
  featuresClientSideId: currentConfig.featuresClientSideId,
};
