<template>
  <v-container fluid>
    <v-card class="pa-2 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar :color="primaryColor" size="40">
          <span class="white--text">{{ nameInitials }}</span>
        </v-avatar>
        <span class="ml-2 font-weight-bold">{{ companyName }}</span>
      </div>
      <div :class="['align-center', inDeleteMode ? 'hide': 'd-flex']">
        <v-btn icon @click="onNewMessage()" :color="primaryColor">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-menu offset-y left transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <div>
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </div>
          </template>
          <v-list>
            <v-list-item @click="enterDeleteMode()" class="more-option">
              <v-list-item-title>Manage Messages</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click="maximize = false">
          <v-icon>mdi-window-minimize</v-icon>
        </v-btn>
        <v-btn icon @click="maximize = true" style="padding-right: 16px">
          <v-icon>mdi-window-maximize</v-icon>
        </v-btn>
        <v-badge
            v-if="!maximize && totalUnreadMessageCount > 0"
            :color="secondaryColor"
            :content="totalUnreadMessageCount"
            class="badge-position"
        ></v-badge>
      </div>
      <div :class="['align-center', inDeleteMode ? 'd-flex': 'hide']">
        <v-btn icon @click="deleteThreads()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon @click="exitDeleteMode()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
    <v-card v-show="maximize">
      <v-text-field
        v-model="searchQuery"
        prepend-icon="mdi-magnify"
        label="Search..."
        single-line
        hide-details
        class="thread-search"
        @input="search"
      ></v-text-field>
    </v-card>
    <v-card v-show="maximize" class="thread-list">
      <v-list>
        <v-list-item 
          v-for="(thread, i) in messageThreads" 
          :key="i"
          :active="activeItem === i"
          :class="['text-subtitle-2', highlightActiveItem(thread) ? 'active-item': null]"
          v-show="showThread(thread)"
          style="padding: 0 8px;"
          @click="selectMessageThread(thread, i)"
        >
          <div class="thread" :style="{backgroundColor: thread.hasUnreadMessages ? lightGrayColor : 'transparent'}">
            <div style="float: right;">
              <p class="text-caption" style="margin-bottom: 0">{{ thread.lastMessageDate }}</p>
              <v-badge
                v-if="thread.hasUnreadMessages"
                :color="secondaryColor"
                :content="thread.unreadMessageCount"
                inline
                style="float: right"
              ></v-badge>
            </div>
            <div class="d-flex align-center">
              <v-avatar :color="primaryColor" size="30">
                <span class="white--text">{{ thread.nameInitials }}</span>
              </v-avatar>
              <span class="ml-2 thread-names">{{ thread.companyNames }}</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card>
      <ChatDialog :show="showChatDialog" :messageThread="selectedMessageThread" @close="toggleChatDialog"></ChatDialog>
      <ChatBox :show="showChatBox" @close="toggleChatBox" @message-sent="handleMessageSent"/>
    </v-card>
  </v-container>
</template>

<script>
import Auth0Mixin from "../../views/Auth0Mixin";
import { mapGetters } from "vuex";
import chatService from "../../services/chatService";
import colors from "../../constants/colors";
import ChatDialog from "./ChatDialog.vue";
import ChatBox from "./ChatBox.vue";
import partyService from "@/services/partyService";

const dateParams = { year: "2-digit", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"};
const usLocale = "en-US";

export default {
  name: "ChatToggle",
  components: {ChatBox, ChatDialog},
  mixins: [Auth0Mixin],
  props: {
    nameInitials: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maximize: false,
      messageThreads: [],
      filteredMessageThreadURLs: [],
      selectedMessageThread: null,
      newMessageChannelURL: null,
      activeItems: [],
      activeItem: null,
      showChatDialog: false,
      showChatBox: false,
      inDeleteMode: false,
      totalUnreadMessageCount: 0,
      searchQuery: "",
      primaryColor: colors.BRAND_PRIMARY_COLOR_3,
      secondaryColor: colors.BRAND_PRIMARY_COLOR_1,
      lightGrayColor: colors.LIGHT_GRAY
    };
  },
  computed: {
    ...mapGetters("chatStore", ["channels"]),
  },
  methods: {
    async processChannels(channels) {
      this.messageThreads.length = 0;

      this.recipientList = await partyService.getChatParties();

      if (this.recipientList.length === 0) {
        return;
      }

      channels.forEach(channel => {
        let messageThread = this.getMessageThread(channel);
        if (messageThread !== null) {
          if (this.newMessageChannelURL != null && messageThread.channelUrl === this.newMessageChannelURL) {
            // Add thread to the beginning of the array if it is the one that a new message was added to
            this.messageThreads.unshift(messageThread);
          } else {
            this.messageThreads.push(messageThread);
          }
        }
      });
      
      if (this.newMessageChannelURL != null) {
        this.openConversation();
      }

      this.filteredMessageThreadURLs = this.messageThreads.map(mt => mt.channelUrl);
      
      this.totalUnreadMessageCount = this.messageThreads.reduce((accumulator, thread) => {
        return accumulator + thread.unreadMessageCount;
      }, 0);
    },
    getMessageThread(channel) {
      if (channel.members.length < 2 || channel.messages.length === 0)
        return null;

      // The user id representing the company that the user is logged in to
      let userId = chatService.chatId;

      // This check is to inhibit communication between a chat that exists and a party that is not connected.
      const connectedParty = this.findConnectedParty(userId, channel.members, this.recipientList);

      if (connectedParty === undefined) {
        return null;
      }

      let companyNames = channel.members.filter(x => x.userId !== userId).map(y => y.nickname).join(", ");
      let nameInitials = channel.memberCount > 2
          ? "G"
          : this.getInitials(companyNames);

      return {
        nameInitials: nameInitials,
        companyNames: companyNames,
        userId: userId,
        lastMessageDate: new Date(channel.lastMessage.createdAt).toLocaleString(usLocale, dateParams),
        hasUnreadMessages: channel.unreadMessageCount > 0,
        unreadMessageCount: channel.unreadMessageCount,
        channelUrl: channel._url,
        messages: channel.messages,
        partyId: connectedParty.id,
        connectedPartyId: connectedParty.connectedPartyId,
        connectedCompanyCode: connectedParty.connectedCompanyCode,
      };
    },
    async selectMessageThread(thread, i) {
      if (this.inDeleteMode) {
        this.toggleSelection(thread);
      } else {
        await this.initChatDialog(thread, i);
      }
    },
    toggleSelection(thread) {
      const index = this.activeItems.indexOf(thread);
      if (index === -1) {
        this.activeItems.push(thread); // Add the item if not already selected
      } else {
        this.activeItems.splice(index, 1); // Remove the item if it is already selected
      }
    },
    async initChatDialog(thread, i) {
      this.activeItem = i;
      
      this.selectedMessageThread = thread;
      this.showChatBox = false;
      this.showChatDialog = true;
      if (thread.hasUnreadMessages) {
        try {
          await chatService.markAsRead(thread.channelUrl);
          thread.hasUnreadMessages = false;
        } catch (error) {
          this.$root.$snackbar.error(error.message);
        }
      }
    },
    getInitials(name) {
      if (name === "")
        return "";
      
      return name
        .match(/\b(\w)/g)
        .join("")
        .substring(0, 2)
        .toUpperCase();
    },
    onNewMessage() {
      if (!this.showChatBox) {
        this.showChatDialog = false;
      }
      this.toggleChatBox();
    },
    toggleChatDialog() {
      this.showChatDialog = !this.showChatDialog;
    },
    toggleChatBox() {
      this.showChatBox = !this.showChatBox;
    },
    enterDeleteMode() {
      this.inDeleteMode = true;
      this.maximize = true;
      this.showChatDialog = false;
    },
    exitDeleteMode() {
      this.inDeleteMode = false;
      this.activeItems.length = 0;
    },
    highlightActiveItem(thread) {
      if (!this.inDeleteMode)
        return false;

      return this.activeItems.includes(thread);
    },
    async deleteThreads() {
      for (const thread of this.activeItems) {
        try {
          // Delete the channel history from SendBird for this user (company)
          await chatService.deleteChannel(thread.channelUrl);

          // Delete the thread from the list displayed in the UI
          const index = this.messageThreads.indexOf(thread);
          if (index !== -1) {
            this.messageThreads.splice(index, 1);
          }
        } catch (error) {
          this.$root.$snackbar.error(error.message);
        }
      }

      this.exitDeleteMode();
    },
    findConnectedParty(userId, members, recipientList) {
      return recipientList.find(party => party.userId === members.find(x => x.userId !== userId).userId);
    },
    async handleMessageSent(channelUrl) {
      this.newMessageChannelURL = channelUrl;
    },
    openConversation() {
      let thread = this.messageThreads.find(t => t.channelUrl === this.newMessageChannelURL);
      if (thread == null) {
        this.$root.$snackbar.error("Unable to open conversation.");
        return;
      }
      
      this.selectedMessageThread = thread;
      this.showChatBox = false;
      this.showChatDialog = true;
      this.newMessageChannelURL = null;
    },
    search() {
      // Minimum 3 characters to search
      if (this.searchQuery.length < 3) {
        this.filteredMessageThreadURLs = this.messageThreads.map(mt => mt.channelUrl);
        return;
      }
      
      this.filteredMessageThreadURLs = [];
      let searchQuery = this.searchQuery.toLowerCase();
      this.messageThreads.forEach(messageThread => {
        // Check if there is match on the name of the recipient or in one of the messages
        if (messageThread.companyNames.toLowerCase().includes(searchQuery) || 
            messageThread.messages.some(msg => msg.message.toLowerCase().includes(searchQuery))) {
          this.filteredMessageThreadURLs.push(messageThread.channelUrl);
        } 
      });
    },
    showThread(thread) {
      return this.filteredMessageThreadURLs.includes(thread.channelUrl);
    }
  },
  watch: {
    channels(newValue) {
      this.processChannels(newValue);
    },
  },
};
</script>

<style>
.thread-list {
  overflow-y: auto;
  max-height: 322px;
}

.thread-names {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thread {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}

.more-option {
  min-height: fit-content !important;
}

.hide  {
  display: none;
}

.active-item {
  background-color: #D3D3D3;
}

.badge-position {
  top: -22px;
  right: -2px;
}

.thread-search {
  padding: 8px !important;
  margin-top: 0 !important;
}

</style>