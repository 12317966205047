<template>
  <v-card v-if="show" class="chatbox">
    <v-card-title class="chatbox-header">
      <span>New Message</span>
      <v-btn icon @click="closeBox">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="chatbox-body">
      <v-select
        v-model="selected"
        :items="recipientList"
        item-text="name"
        item-value="userId"
        chips
        outlined
        label="Select recipients"
      >
        <template #selection="{ item }">
          <v-chip class="ma-2">{{ item.name }}</v-chip>
        </template>
        <template #item="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="24" class="mr-2">{{ item.initials }}</v-avatar>
            <span>{{ item.name }}</span>
          </div>
        </template>
      </v-select>

      <v-textarea
        v-model="msg"
        outlined
        label="Write a message..."
        auto-grow
      ></v-textarea>

      <v-btn block :color="primaryColor" class="white--text" @click="sendMessage">
        Send
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import chatService from "@/services/chatService";
import partyService from "@/services/partyService";
import colors from "../../constants/colors";

export default {
  name: "ChatBox",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipientList: [], 
      selected: null,
      msg: "",
      primaryColor: colors.BRAND_PRIMARY_COLOR_3,
    };
  },
  methods: {
    async init() {
      this.recipientList = await partyService.getChatParties();

      this.recipientList = this.recipientList.map(party => ({
        id: party.id,
        name: party.name,
        initials: party.name.charAt(0).toUpperCase(),
        userId: party.userId,
        connectedCompanyCode: party.connectedCompanyCode,
        connectedPartyId: party.connectedPartyId,
      }));
    },
    closeBox() {
      this.$emit("close");
    },
    async sendMessage() {
      if (!this.msg || this.selected === null) {
        this.$root.$snackbar.message("Please select at least one recipient and write a message.");
        return;
      }
      
      try {
        const toParty = this.recipientList.find((r) => r.userId === this.selected);
        if (toParty) {
          let channelUrl = await chatService.sendMessage(toParty, this.msg);
          this.$emit('message-sent', channelUrl);
          this.msg = "";
          this.selected = null;
        } else {
          this.$root.$snackbar.error("Failed to send the message. Please try again.");
        }
      } catch (error) {
        this.$root.$snackbar.error("Failed to send the message. Please try again.");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.chatbox {
  position: fixed;
  bottom: 20px;
  right: 370px;
  width: 300px;
  z-index: 2000;
}

  .chatbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chatbox-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
</style>