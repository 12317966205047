import SendbirdChat from '@sendbird/chat'
import {GroupChannelHandler, GroupChannelModule} from '@sendbird/chat/groupChannel';
import appSettings from "@/appSettings";
import store from "@/store";

const channelHandlers = {
    onChannelsAdded: (context, channels) => {
        /*const updatedChannels = [...channels, ...stateRef.current.channels];
        updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });*/
        console.log('you have been added to channels ...................', channels);
        channels.forEach(c => console.log(`you have been added to channel ${c.name}`));
    },
    onChannelsDeleted: (context, channels) => {
        /*const updatedChannels = stateRef.current.channels.filter((channel) => {
            return !channels.includes(channel.url);
        });
        updateState({ ...stateRef.current, channels: updatedChannels });*/
        console.log('deleted channel ...............', channels);
        channels.forEach(c => console.log(`channel ${c.name} has been deleted`));
    },
    onChannelsUpdated: (context, channels) => {
        // const updatedChannels = stateRef.current.channels.map((channel) => {
        //     const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
        //     if (updatedChannel) {
        //         return updatedChannel;
        //     } else {
        //         return channel;
        //     }
        // });

        // updateState({ ...stateRef.current, channels: updatedChannels });
        console.log('updated channel ..................', channels);
        channels.forEach(c => console.log(`channel ${c.name} was updated`));
    },
    onMessagesAdded: (context, channel, messages) => {
        console.log(`message received on the channel that you are part in`);
        console.log('messages are: ');
        messages.forEach(m => {
            console.log(m);
        });
    },
    onMessagesUpdated: (context, channel, messages) => {
        console.log(`message received on the channel that you are part in`);
        console.log('messages are: ');
        messages.forEach(m => {
            console.log(m);
        });
    }
};

export default {
    chat: null,
    company: null,
    chatId: null,
    async init(company) {
        this.company = company;
        this.chat = SendbirdChat.init({
            appId: appSettings.sendBirdAppId,
            modules: [
                new GroupChannelModule(),
            ],
        });
        
        try {
            this.chatId = this.company.code + '-' + this.company.pakServiceId;
            var resultUser = await this.chat.connect(this.chatId);

            console.log("Connected to chat", company, resultUser);
            
            const userUpdateParams = {
                nickname: company.name,
                userId: this.chatId
            };
            resultUser = await this.chat.updateCurrentUserInfo(userUpdateParams)
            console.log(resultUser);

        } catch (e) {
            console.log(e);
        }
    },
    async loadChannels() {
        try {
            const groupChannelFilter = new GroupChannelModule();
            groupChannelFilter.includeEmpty = true;
            var collection = this.chat.groupChannel.createGroupChannelCollection({
                filter: groupChannelFilter,
                order: GroupChannelModule.LATEST_LAST_MESSAGE
            });
            collection.setGroupChannelCollectionHandler(channelHandlers);

            const handler = new GroupChannelHandler();
            handler.onMessageReceived = (channel, message) => {
                this.handleNewMessage(channel, message);
            };
            this.chat.groupChannel.addGroupChannelHandler('NEW_MESSAGE_HANDLER', handler);
            
            let groupChannels = await collection.loadMore();
            
            for (const channel of groupChannels) {
                await this.loadMessages(channel);
            }

            console.log(groupChannels);
            store.commit("chatStore/updateChannels", groupChannels);

        } catch (e) {
            console.log(e);
        }
    },
    async loadMessages(channel) {
        const params = {
            limit: 100,
            reverse: true,
        };

        const query = channel.createPreviousMessageListQuery(params);
        try {
            channel.messages = await query.load();
        } catch(e) {
            console.log(e);
        }
    },
    async createGroup(toParty) {
        try {
            const groupName = this.getGroupName(toParty);
            const reverseGroupName = this.getGroupNameReversed(toParty);

            const existingChannel = store.getters["chatStore/channels"].find(
                (channel) => channel.name === groupName || channel.name === reverseGroupName
            );

            if (existingChannel) {
                console.log("Channel already exists:", existingChannel);
                return existingChannel;
            }

            const metadata = JSON.stringify({
                fromCompanyId: this.company.id,
                fromCompanyCode: this.company.code,
                toCompanyCode: toParty.connectedCompanyCode,
                fromPartyId: toParty.id,
                toPartyId: toParty.connectedPartyId,
                toCompanyId: toParty.connectedCompanyId
            });

            const channelParams = {
                name: groupName,
                invitedUserIds: [toParty.userId],
                isDistinct: false,
                data: metadata,
            };

            const newChannel = await this.chat.groupChannel.createChannel(channelParams);
            console.log("Created new channel:", newChannel);

            store.commit("chatStore/updateChannels", [...store.getters["chatStore/channels"], newChannel]);

            return newChannel;
        } catch (error) {
            console.error("Error creating group:", error);
            throw error;
        }
    },
    async sendMessage(toParty, msg) {
        const channel = await this.createGroup(toParty);
        let msgParams = { message: msg };

        return new Promise((resolve, reject) => {
            channel.sendUserMessage(msgParams)
                .onSucceeded((message) => {
                    this.handleNewMessage(channel, message);
                    resolve(channel._url);
                })
                .onFailed((error) => {
                    reject(error);
                });
        });
    },
    handleNewMessage(channel, message) {
        let channels = store.getters["chatStore/channels"];
        let updatingChannel = channels.find((c) => c._url === channel._url);
        if (updatingChannel.messages == null) {
            updatingChannel.messages = [message]
        } else {
            updatingChannel.messages.unshift(message);
        }
        store.commit("chatStore/updateChannels", channels);
    },
    async getChannel(channelURL) {
        return await this.chat.groupChannel.getChannel(channelURL);
    },
    async deleteChannel(channelURL) {
        const channel = await this.getChannel(channelURL);
        await channel.resetMyHistory();
    },
    async markAsRead(channelURL) {
        const channel = await this.getChannel(channelURL);
        await channel.markAsRead();
    },
    getGroupName(toParty) {
        return `${this.company.code}_${toParty.connectedCompanyCode}`;
    },
    getGroupNameReversed(toParty) {
        return `${toParty.connectedCompanyCode}_${this.company.code}`;
    }
}