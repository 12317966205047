<template>
  <div>
    <ChatToggle :nameInitials="nameInitials" :companyName="companyName" @toggle="toggleChatBox"/>
  </div>
</template>
  
<script> 
import ChatToggle from "./ChatToggle.vue";
import companyService from "@/services/companyService";
import { mapGetters } from "vuex";

export default {
  name: "ChatContainer",
  components: { ChatToggle },
  data() {
    return {
      showChatBox: false,
      nameInitials: "",
      companyName: "",
      hasMessagingRole: false,
      messagingFlagEnabled: false,
      loading: true,
      company: {},
    };
  },
  methods: {
    async init() {
      const companyId = this.selectedCompanyId; 
      
      if (companyId) {
        this.companyName = await companyService.getName(companyId);
        this.nameInitials = this.companyName.match(/\b(\w)/g).join("").toUpperCase();
      }

      this.loading = false;
    },
    toggleChatBox() {
        this.showChatBox = !this.showChatBox;
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId"]),
  },
};
</script>
  