export default {
    namespaced: true,

    state: {
        messagingFlag: false,
    },

    getters: {
        messagingFlag: state => state.messagingFlag,
    },
    
    mutations: {
        mutateMessagingFlag: (state, messagingFlag) => (state.messagingFlag = messagingFlag),
    },
    
    actions: {
        updateMessagingFlag({ commit }, value) {
            commit("mutateMessagingFlag", value);
        },
    },
};
