import Vue from "vue";
import Vuex from "vuex";
import auth0Store from "./auth0Store";
import appStore from "./appStore";
import terminologyStore from "./terminologyStore";
import documentTypeStore from "./documentTypeStore";
import userProfile from "./userProfile";
import logoStore from "./logoStore";
import invitationStore from "./invitationStore";
import chatStore from "./chatStore";
import featuresStore from "./featuresStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth0Store,
    appStore,
    terminologyStore,
    userProfile,
    documentTypeStore,
    logoStore,
    invitationStore,
    chatStore,
    featuresStore,
  },
});
